<template>
  <div class="mainDiv">
    <Pano :source="src"></Pano>
  </div>
</template>
<script>
  /*eslint-disable*/
  import { Pano } from 'vuejs-vr'
  export default {
    name: 'Custom360ViewerVueJSVR',
    components: {
      Pano
    },
    props: {
      src: String
    },
    created() {
      
    }
  }
</script>
<style scoped>
  .mainDiv {
    width: 100%;
    height: 100%;
  }
</style>