<template>
  <div id="app">
    <Custom360ViewerVueJSVR :src="imgSrc"/>
  </div>
</template>

<script>
import Custom360ViewerVueJSVR from './components/Custom360ViewerVueJSVR.vue'

export default {
  name: 'App',
  components: {
    Custom360ViewerVueJSVR
  },
  data() {
    return {
      imgSrc: ""
    }
  },
  created() {
    var urlString = window.location.href
    var urlObj = new URL(urlString)
    var imgName = urlObj.searchParams.get("src")
    // console.log(imgName);
    if (!imgName) {
      var hrefSplit = window.location.href.split("?")
      var imgString = hrefSplit.pop().split("=")
      imgName = imgString.pop()
    }
    this.imgSrc = "images/" + imgName
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 97vh;
  width: 99vw;
  overflow: hidden;
}
</style>
